import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slika1 from '../img/ai-generated-82930752.jpeg';
import slika2 from '../img/tire-6941103.webp';
import slika3 from '../img/car-4048220_1280.jpeg';
import slikaz from '../img/ai-generated-8293075 (2).webp';
import '../App.css';
import { Fade } from 'react-awesome-reveal';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '10px', fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '10px', zIndex: 1, fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};


const Slajder = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div>
      <div className="slider-container">
        <Slider {...settings}>
          <div className="slide align-top">
             <img src={slika1} alt="Slika 1" className='slika1' style={{ width: '100%', height: 'auto'}} /> 
            <img src={slikaz} alt="Slika 1" className='slikaz h-100' style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1'>
                <Fade direction='top' triggerOnce>
                <h2 className='krivi'>Mi cenimo vaše vozilo</h2>
                <h2 className='zelen'>Pneumatik Centar</h2>
                </Fade>
              </div>
            </div>
          </div>
          <div className="slide ">
            <img src={slika2} alt="Slika 3" className="h-100" style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1 t2'>
              <Fade direction='top' triggerOnce>
                <h2 className='krivi'>Bezbednost na prbom mestu</h2>
                <h2 className='zelen'>Popravka pneumatika <br />kvalitetno i sigurno</h2>
                </Fade>
              </div>
            </div>
          </div>
          <div className="slide ">
            <img src={slika3} alt="Slika 2" className='h-100' style={{ width: '100%', height: 'auto' }} />
            <div className="slide-text">
              <div className='t1'>
              <Fade direction='top' triggerOnce>
                <h2 className='krivi'>Najbolji u Gradu</h2>
                <h2 className='zelen'>Vulkanizerska radnja i <br /> Prodavnica Guma</h2>
                </Fade>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Slajder;
