import React from 'react';
import '../App.css'; // Stilizacija komponente
import slika2 from '../img/tyre-1714669.webp'; // Učitajte odgovarajuću sliku
import slika1 from '../img/car_820898.jpeg'; // Učitajte odgovarajuću sliku

const Sigurnost = () => {
  return (
    <div className='sig-back'>
      <div className="container horizontal-cards-container">
        <h2 className='text-center text-white mb-4'><b>Sigurnost</b></h2>
        <div className="row mb-4">
          <div className="col-lg-6 mb-3">
            <div className="horizontal-card h-100">
              <img src={slika1} alt="Repair" className="horizontal-card-img-left" />
              <div className="horizontal-card-body">
                <p className="horizontal-card-text">
                  Guma se može popraviti tj. osposobiti za upotrebu na više načina. Skoro svaka od tih metoda će oštećeni pneumatik vratiti u funkciju. Ali dovodimo u pitanje da li je popravljeni pneumatik bezbedan za vožnju i koliko dugo će trajati. Sve opravke izvodimo sa posebnim osvrtom na bezbednost jer kad je bezbednost u pitanju tu ne pravimo kompromis.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="horizontal-card h-100">
              <img src={slika2} alt="Repair" className="horizontal-card-img-left" />
              <div className="horizontal-card-body">
                <p className="horizontal-card-text">
                  Kada je u pitanju popravka oštećenih pneumatika, čak 95% svih mogućih oštećenja bude popravljeno od strane naših iskusnih vulkanizera.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sigurnost;
