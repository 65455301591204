import React, { useEffect, useState } from 'react';
import slika1 from '../img/Car-Tyre-Insurance.jpeg';
import slika3 from '../img/flat-tire-76563.jpeg';
import slika2 from '../img/rim-2296197_1280.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';

const Onama = () => {
  const [countTo10, setCountTo10] = useState(0);
  const [countTo2000, setCountTo2000] = useState(0);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setCountTo10(prevCount => {
        if (prevCount < 10) {
          return prevCount + 1;
        } else {
          clearInterval(interval1);
          return 10;
        }
      });
    }, 200);

    const interval2 = setInterval(() => {
      setCountTo2000(prevCount => {
        if (prevCount < 2000) {
          return prevCount + 20;
        } else {
          clearInterval(interval2);
          return 2000;
        }
      });
    }, 20);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  return (
    <div className='container'>
    <div className="container-fluid">
      <div className="row">
        {/* Prva polovina */}
        
        <div className="col-md-6">
        <Fade direction='left' triggerOnce> <h5 className='pt-5 onama-k'>Mi cenimo vaše vozilo</h5> 
          <h3>Vulkanizerska radnja i prodavnica auto guma</h3>
          <p>
             Pneumatik Centar je opremljen najsavremenijim mašinama, za montažu, balansiranje i vulkaniziranje točkova. Pružamo sve vrste vulkanizerskih usluga u najkraćem mogućem roku. Višegodišnje iskustvo, zadovoljni klijenti, konstantno ulaganje u opremu i uređaje, kao i kvalifikovana radna snaga.
          </p>
          </Fade>
          <ul className="prodaja-guma-list list-unstyled pt-5">
            <li className="d-flex align-items-center mb-2">
            <Fade direction="left" triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
            <Fade direction="right" triggerOnce>Sve vrste vulkanizerskih usluga</Fade>
            </li>
            <li className="d-flex align-items-center mb-2">
            <Fade direction="left" triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
            <Fade direction="right" triggerOnce> Specijalizovane usluge, prilagođene vašim potrebama </Fade>
            </li>
            <li className="d-flex align-items-center mb-2">
            <Fade direction="left" triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
            <Fade direction="right" triggerOnce> 95% svih mogućih oštećenja bude popravljeno</Fade>
            </li>
          </ul>
          
        </div>

        {/* Druga polovina */}
        <div className="col-md-6">
          <div className="row">
            {/* Brojač do 10 */}
            <div className="col-md-6 text-center mb-4 mt-4">
              
              <div className="mb-3">
                <span className='count'>{countTo10}</span>
                <h5>Godina iskustva</h5>
              </div>
            </div>

            {/* Brojač do 2000 */}
            <div className="col-md-6 text-center mb-4 mt-4">
              
              <div className="mb-3">
                <span className='count'>{countTo2000}</span>
                <h5>Zadovoljnih klijenata</h5>
              </div>
            </div>
          </div>

          {/* Slike ispod brojača */}
          <div className="row">
            <div className="col-md-6 text-center mb-4">
              <img src={slika1} alt="Ismage 2" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover'  }} />
            </div>
            <div className="col-md-6 text-center mb-4">
              <img src={slika2} alt="Ismage 3" className="img-fluid mb-1" style={{ width: '100%', height: 'auto' }} /> 
              <img src={slika3} alt="Ismage 4" className="img-fluid" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Onama;
