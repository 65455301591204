import React, { useRef } from 'react';
import '../App.css';
import logo from '../img/Pneumatik-Centar-Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const navbarRef = useRef(null);

  const toggleNavbar = () => {
    if (navbarRef.current.classList.contains('show')) {
      navbarRef.current.classList.remove('show');
    } else {
      navbarRef.current.classList.add('show');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light px-md-4">
      <div className='container'>
        <a className="navbar-brand" href="/#Home">
          <img
            src={logo}
            height="50"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </a>
        <a href="tel:+381 0691020938" className="btn btn-outline-primary d-lg-none">
          <FontAwesomeIcon icon={faPhoneAlt} />
        </a>
        <button className="navbar-toggler ms-4" type="button" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={faBars} />
        </button>        
        <div className="collapse navbar-collapse justify-content-center" ref={navbarRef}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/#Home">Početna</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Services">Usluge</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#About">O nama</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#Contact">Kontakt</a>
            </li>
          </ul>
        </div>
        <a href="tel:+123456789" className="btn btn-outline-primary ms-auto d-none d-lg-inline">
          <FontAwesomeIcon icon={faPhoneAlt} className="me-2" />
          Pozovite nas
        </a>
      </div>
    </nav>
  );
};

export default Header;
