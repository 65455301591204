import React from 'react';
import '../App.css'; // CSS za stilizaciju ikonica
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faUserTie, faMoneyBillWave, faShieldAlt, faClock, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Prednosti = () => {
  return (
    <div className='prednosti-back'>
        <h5 className='usluge-title text-center pt-4'>Zašto baš mi</h5>
        <h3 className='text-center'><b>Naše prednosti</b></h3>
    <div className='container'>
    <div className="row">
      <div className="col-md-4 p-3 mb-1">
        <div className="custom-card p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faTools} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Savremene mašine</h5>
              <p className="card-text">Savremene mašine nam omogućavaju brzu i kvalitetnu uslugu</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-3  mb-1">
        <div className="custom-card  p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faUserTie} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Iskustvo radnika</h5>
              <p className="card-text">Naše stručno osoblje ima višegodišnje iskustvo</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4  p-3 mb-1">
        <div className="custom-card p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faMoneyBillWave} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Niske cene</h5>
              <p className="card-text">Nasim klijentima pružamo najbolje moguće cene</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-3 mb-1">
        <div className="custom-card p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faCheckCircle} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Kvalitet</h5>
              <p className="card-text">95% svih mogućih oštećenja bude popravljeno</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-3 mb-1">
        <div className="custom-card p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faShieldAlt} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Sigurnost</h5>
              <p className="card-text">Sve opravke izvodimo sa posebnim osvrtom na bezbednost</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 p-3 mb-1">
        <div className="custom-card p-3">
          <div className="card-body d-flex align-items-center">
            <div className="icon bg-danger">
              <FontAwesomeIcon icon={faClock} className="text-white" />
            </div>
            <div className="text">
              <h5 className="card-title">Brza usluga</h5>
              <p className="card-text">Trudimo se da naše usluge završavamo brzo i efikasno</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Prednosti;
