import './App.css';
import { Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import { useEffect } from 'react';
import PrivacyPolicy from './pages/privacy-policy'
import CookiePolicy from './pages/cookie-policy';
import BackToTopButton from './components/BackToTopButton';
import Footer from './components/Footer';


function App() {
  useEffect(() => {
    document.title = "Pneumatik Centar";
  }, []);
  return (
    <>
    
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
      <Footer />
      <BackToTopButton />
    </>
  );
}

export default App;
