import React from 'react';
import '../App.css'; // Stilizacija komponente
import slika1 from '../img/transport-3366409_1280.jpeg';
import slika2 from '../img/tire-114259.webp';
import slika3 from '../img/tire-1749597-1-.webp';
import slika4 from '../img/tire-service-2291880.webp';

const Usluge = () => {
  return (
    <div className=' d-flex uslugeback'>
      <div className="container">
        <div className=''>
          <h3 className='usluge-title pt-5'>Popravka i Održavanje</h3>
          <h1 className='usluge-title1 mb-4'>Naše Usluge</h1>
          <div className="row d-flex">
            <div className="col-md-3 mb-2  pb-0 p-1 flex-fill">
              <div className="card1 mb-3">
                <div className="card-body text-left p-0">
                  <img src={slika3} alt='slika' className='slike card-img-top' />
                  <div className='p-3 pt-0'>
                    <h5 className="card-title mt-3">Balansiranje Točka</h5>
                    <p className="card-text">Balansiranje točka je postupak u kom se masa točka uravnotežava i pri tome smanjuju vibracije na volanu ili celom vozilu, produžava životni vek gume. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-2  pb-0 p-1 flex-fill">
              <div className="card1 mb-3">
                <div className="card-body text-left p-0">
                  <img src={slika1} alt='slika' className='slike card-img-top' />
                  <div className='p-3 pt-0'>
                    <h5 className="card-title mt-3">Hladna Fleka</h5>
                    <p className="card-text">Ovim postupkom se ostvaruje kvalitetna popravka ostecenog pneumatika. Hladna fleka se preporučuje za oštećenja nepravilnog oblika</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-2  pb-0 p-1 flex-fill">
              <div className="card1 mb-3">
                <div className="card-body text-left p-0">
                  <img src={slika2} alt='slika' className='slike card-img-top' />
                  <div className='p-3 pt-0'>
                    <h5 className="card-title mt-3">Popravka tubeles gume</h5>
                    <p className="card-text">Ovo je brz i jeftin način popravke guma</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-2  pb-0 p-1 flex-fill">
              <div className="card1 mb-3">
                <div className="card-body text-left p-0">
                  <img src={slika4} alt='slika' className='slike card-img-top' />
                  <div className='p-3 pt-0'>
                    <h5 className="card-title mt-3">Sezonska zamena</h5>
                    <p className="card-text mt-3">Ova oblast je regulisana zakonom, kojim se propisuje da zimske gume moraju biti na vozilu od 1. Novembra do 1. Aprila</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usluge;
