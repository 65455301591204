import React from 'react';
import Slajder from '../components/Slider';
import LocationCard from '../components/Info';
import Call from '../components/Call';
import Usluge from '../components/Usluge';
import ProdajaGuma from '../components/ProdajaGuma';
import Sigurnost from '../components/Sigurnost';
import Map from '../components/Map';
import Prednosti from '../components/Prednosti';
import Onama from '../components/O-nama';

const Home = () => {
  return (
    <>
    <div className='app'>
    <Slajder />
    <LocationCard />
    <section id='About'>
    <Onama />
    </section>
    <Call />
    <Prednosti />
    <section id='Services'>
    <Usluge />
    </section>
    <section id='Tire-sales'>
    <ProdajaGuma />
    </section>
    <Sigurnost />
    <section id='Contact'>
    <Map />
    </section>    
    </div>
    </>
  );
};

export default Home;
