import React from 'react';
import '../App.css'; // Stilizacija komponente
const Call = () => {
    return (

        <div className='infoback1' >
            <h1 className='call'>Pneumatik Centar</h1>
            <button className='btn call-btn'><a href='tel:+381 0691020938' className='a1'>069 10 20 938</a></button>
        </div>
    );
};
export default Call;