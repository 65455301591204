import React from 'react';
import '../App.css'; // Stilizacija komponente
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCar, faWrench, faClock } from '@fortawesome/free-solid-svg-icons'; // Uvoz ikonica koje želite koristiti

const LocationCard = () => {
  return (
    <div className='infoback' >
    <div className="container infoY">
    <div className="row d-flex">
      <div className="col-md-3  p-0 flex-fill">
        <div className="card mb-3 pt-3">
          <div className="card-body text-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
            <h5 className="card-title mt-3">Lokacija</h5>
            <p className="card-text">Orašački put bb, Aranđelovac</p>
          </div>
        </div>
      </div>
      <div className="col-md-3 p-0 flex-fill">
        <div className="card mb-3 pt-3">
          <div className="card-body text-center">
            <FontAwesomeIcon icon={faCar} className="location-icon" />
            <h5 className="card-title mt-3">Prodaja novih guma</h5>
            <p className="card-text">Vulkanizerska radnja takođe vrši prodaju guma svih renomiranih svetskih proizvođača.</p>
          </div>
        </div>
      </div>
      <div className="col-md-3  p-0 flex-fill">
        <div className="card mb-3 pt-3">
          <div className="card-body text-center">
            <FontAwesomeIcon icon={faWrench} className="location-icon" />
            <h5 className="card-title mt-3">Popravka guma</h5>
            <p className="card-text">Čak 95% svih mogućih oštećenja bude popravljeno od strane naših iskusnih vulkanizera.</p>
          </div>
        </div>
      </div>
      <div className="col-md-3  p-0 flex-fill">
        <div className="card mb-3 pt-3">
          <div className="card-body text-center">
            <FontAwesomeIcon icon={faClock} className="location-icon" />
            <h5 className="card-title mt-3">Zakažite Termin</h5>
            
            <p className="card-text mt-3">Zakažite svoj termin na vreme <br />tel:<b> 069 10 20 938</b></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
};

export default LocationCard;
