import React from 'react';

const Map = () => {
    return (

        <section className="contact-page-map wow fadeInUp delay-0-2s">
            <div>
                <iframe src="https://maps.google.com/maps?q=44.3024842,20.5962521&z=15&output=embed&cid=13129276105710864129" width="100%" height="450" frameborder="0"  title=' ' className='align-top'></iframe>
            </div>
        </section>
    );
};
export default Map;