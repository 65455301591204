import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt,  faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h2>O Nama</h2>
                        <p>Naše stručno osoblje će vam izaći u susret i pomoći da prevaziđete sve vaše probleme.</p>
                        
                    </div>
                    <div className="col-md-3">
                        <h2>Osnovne Usluge</h2>
                        <ul className="list-unstyled">
                            <li className='li-b pb-2'><a href="#Services" className='a'><FontAwesomeIcon icon={faArrowRight} /> Demontaža gume </a></li>
                            <li className='li-b pb-2'><a href="#Services" className='a'><FontAwesomeIcon icon={faArrowRight} /> Montaža gume </a></li>
                            <li className='li-b pb-2'><a href="#Services" className='a'><FontAwesomeIcon icon={faArrowRight} /> Krpljenje unutrašnje gume </a></li>
                            <li className='li-b pb-2'><a href="#Services" className='a'><FontAwesomeIcon icon={faArrowRight} /> Balansiranje točka </a></li>
                            <li className='li-b pb-2'><a href="#Services" className='a'><FontAwesomeIcon icon={faArrowRight} /> Hladna fleka </a></li>
                            <li className='li-b pb-2'><a href="#Tire-sales" className='a'><FontAwesomeIcon icon={faArrowRight} /> Prodaja novih guma </a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h2>Kontakt</h2>
                        <ul className="list-unstyled">
                            <li className='li1'><a href="tel:+381 0691020938"><FontAwesomeIcon icon={faPhone} className='f-icon' /></a><br /> 069 10 20 938</li>
                            <li className='li1'><a href="mailto:pneumatik.centar.ar@gmail.com"><FontAwesomeIcon icon={faEnvelope} className='f-icon' /></a><br /> pneumatik.centar.ar@gmail.com</li>
                            <li className='li1'><a href="#Contact"><FontAwesomeIcon icon={faMapMarkerAlt} className='f-icon' /></a><br /> Orašački put bb</li>
                            
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h2>Radno Vreme</h2>
                        <table className="table">
                            <tbody className='tb'>
                                <tr><td className='td2'>Ponedeljak</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Utorak</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Sreda</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Četvrtak</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Petak</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Subota</td><td className='td2'>07:30 - 19:00</td></tr>
                                <tr><td className='td2'>Nedelja</td><td className='td1'>Ne radimo</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-center pt-2 pb-3">
                    <Link to="/privacy-policy" className="mx-3 ftxt">Politika privatnosti</Link>
                    <Link to="/cookie-policy" className="mx-3 ftxt">Kolačići</Link>
                 </div>
            </div>
        </footer>
    );
}

export default Footer;
