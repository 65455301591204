import React from 'react';
import '../App.css'; // Stilizacija komponente
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';

const ProdajaGuma = () => {
  return (
    <div className="container prodaja-guma-container">
      <div className="row justify-content-center">
        <Fade direction='left' triggerOnce>
        <div className="col-12 text-center">
          <h1 className="prodaja-guma-title">Prodaja novih guma</h1>
        </div>
        <div className="col-12">
          <p className="prodaja-guma-text">
            Još jedna usluga sa dugogodišnjom tradicijom, vulkanizerska radnja takođe vrši prodaju guma svih renomiranih svetskih proizvođača. Naša radnja je fokusirana na širinu ponude. Gume u našoj ponudi imaju cenovni raspon od najjeftinijih do najskupljih pneumatika za Vaš automobil. Pored putničkih pneumatika u ponudi su i gume za terenska i laka dostavna vozila.
          </p>
        </div>
        </Fade>
        <div className="col-12">
          <ul className="prodaja-guma-list list-unstyled">
            <li className="d-flex align-items-center mb-2">
              <Fade direction='left' triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
              <Fade direction='right' triggerOnce>Svi renomirani svetski proizvođači</Fade>
            </li>
            <li className="d-flex align-items-center mb-2">
            <Fade direction='left' triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
            <Fade direction='right' triggerOnce> Gume za terenska i laka dostavna vozila</Fade>
            </li>
            <li className="d-flex align-items-center mb-2">
            <Fade direction='left' triggerOnce><FontAwesomeIcon icon={faCheckCircle} className="check-icon mr-2" /></Fade>
            <Fade direction='right' triggerOnce>Fokus na širinu ponude</Fade>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProdajaGuma;
